import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['flashes', 'flashesStick']
  connect() {
    this.flashesHeight = this.flashesTarget.offsetHeight;
  }

  displayFlashes() {
    if (window.scrollY >= (this.flashesHeight + 64)) {
      this.flashesStickTarget.classList.remove('hidden')
    } else  {
      // Reset Style
      this.flashesStickTarget.classList.add('hidden')
    }
  }
}
