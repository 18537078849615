// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import "./leaflet.js"
import "chartkick/chart.js"
import 'lightgallery/css/lightgallery.css'

ActiveStorage.start()

import "./controllers"
