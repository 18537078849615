import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sliderOne', 'sliderTwo', 'displayValOne', 'displayValTwo', 'sliderTrack']

  connect() {
    this.slideOne();
    this.slideTwo();
    this.inputOne();
    this.inputTwo();
  }

  slideOne() {
    if(parseInt(this.sliderTwoTarget.value) - parseInt(this.sliderOneTarget.value) <= 0){
      this.sliderOneTarget.value = parseInt(this.sliderTwoTarget.value) - 0;
    }
    this.displayValOneTarget.value = this.sliderOneTarget.value;
    this.fillColor();
  }
  slideTwo() {
    if(parseInt(this.sliderTwoTarget.value) - parseInt(this.sliderOneTarget.value) <= 0){
        this.sliderTwoTarget.value = parseInt(this.sliderOneTarget.value) + 0;
    }
    this.displayValTwoTarget.value = this.sliderTwoTarget.value;
    this.fillColor();
  }

  inputOne() {
    if(parseInt(this.displayValTwoTarget.value) - parseInt(this.displayValOneTarget.value) <= 0){
      this.displayValOneTarget.value = parseInt(this.displayValTwoTarget.value) - 0;
    }
    this.sliderOneTarget.value = this.displayValOneTarget.value;
    this.fillColor();
  }
  inputTwo() {
    if(parseInt(this.displayValTwoTarget.value) - parseInt(this.displayValOneTarget.value) <= 0){
      this.displayValTwoTarget.value = parseInt(this.displayValOneTarget.value) - 0;
    }
    this.sliderTwoTarget.value = this.displayValTwoTarget.value;
    this.fillColor();
  }

  fillColor() {
    var percent1 = (this.sliderOneTarget.value / this.sliderOneTarget.max) * 100;
    var percent2 = (this.sliderTwoTarget.value / this.sliderOneTarget.max) * 100;
    this.sliderTrackTarget.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #312e51 ${percent1}% , #312e51 ${percent2}%, #dadae5 ${percent2}%)`;
  }
}
