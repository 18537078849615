import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchForm', 'showIcon', 'hideIcon']

  connect() {
    this.searchFormTarget.open = false;
  }

  toggle (event) {
    this.searchFormTarget.open === false ? this.hide(event) : this.show(event)
  }

  show (event) {
    event.preventDefault();

    this.open = true

    this.hideIconTarget.classList.remove('hidden');
    this.showIconTarget.classList.add('hidden');
  }

  hide (event) {
    event.preventDefault();

    this.open = false

    this.hideIconTarget.classList.add('hidden');
    this.showIconTarget.classList.remove('hidden');
  }
}
