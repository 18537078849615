import Carousel from 'stimulus-carousel'
export default class extends Carousel {
  connect() {
    super.connect()
    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {}
  }
  slide_to(event) {
    this.swiper.slideTo(event.params.slide)
  }
}
