import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'direction', 'form']

  sort(event) {
    // get current url with params
    let url = new URL(window.location.href);

    // get the target param "data-column"
    let column = event.target.dataset.column;

    let new_column = this.fieldTarget.value !== column;

    this.fieldTarget.value = column;
    this.directionTarget.value = new_column ? "asc" : this.nextDirection(column);

    this.formTarget.requestSubmit();
  }

  nextDirection(column) {
    // get the current direction
    let current_direction = this.directionTarget.value;

    // if the current direction is empty or ascending, set to descending
    if (current_direction == "" || current_direction == "desc") {
      return "asc";
    } else {
      // if the current direction is descending, set to ascending
      return "desc";
    }
  }
}
