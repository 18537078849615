import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['header', 'flashComponent']

  connect() {
    this.flashHeight = this.flashComponentTarget.offsetHeight;
    this.onScrollRunning = true;
  }
  scrollHeader() {
    if (this.flashHeight > 0) {
      if (window.scrollY >= (this.flashHeight + 64)) {
      this.headerTarget.classList.add('stick')
      } else  {
        // Reset Style
        this.headerTarget.classList.remove('stick')
      };
      this.onScrollRunning = false;
    } else {
      if (window.scrollY >= 64) {
      this.headerTarget.classList.add('stick')
      } else  {
        // Reset Style
        this.headerTarget.classList.remove('stick')
      }
      this.onScrollRunning = false;
    }
  }
}
