import { Application } from "@hotwired/stimulus"
import Lightbox from 'stimulus-lightbox';
const application = Application.start()
import { Slideover } from 'tailwindcss-stimulus-components';
import { Tabs } from 'tailwindcss-stimulus-components';

// Or in your application.scss file
// Configure Stimulus development experience
application.register('tabs', Tabs)
application.register('slideover', Slideover)
application.register('lightbox', Lightbox)
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }
