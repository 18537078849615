import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.submitForm.bind(this))
  }

  submitForm() {
    this.element.requestSubmit()
  }
}
