import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown', 'content', 'region', 'department', 'city', 'contentRegion', 'contentDepartment', 'contentCity', 'labelWallForSale']

  toggle() {
    if( !this.dropdownTarget.checked ) {
      this.contentTarget.classList.add('hidden')
    } else {
      this.contentTarget.classList.remove('hidden')
    }
  }

  toggle_region() {
    if( !this.regionTarget.checked ) {
      this.contentRegionTarget.classList.add('hidden')
    } else {
      this.contentRegionTarget.classList.remove('hidden')
    }
  }

  toggle_department() {
    if( !this.departmentTarget.checked ) {
      this.contentDepartmentTarget.classList.add('hidden')
    } else {
      this.contentDepartmentTarget.classList.remove('hidden')
    }
  }

  toggle_city() {
    if( !this.cityTarget.checked ) {
      this.contentCityTarget.classList.add('hidden')
    } else {
      this.contentCityTarget.classList.remove('hidden')
    }
  }

  toggle_label_wall_for_sale() {
    if( this.dropdownTarget.checked ) {
      this.labelWallForSaleTarget.innerHTML = 'Prix des murs (€)'
    } else {
      this.labelWallForSaleTarget.innerHTML = 'Loyer des murs annuel TTC (€)'
    }
  }
}
