import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"
import "tom-select/dist/css/tom-select.default.min.css";

// Connects to data-controller="ts--select"
export default class extends Controller {
  static values = { url: String }

  connect() {

    var config = {
      plugins: ['clear_button'],
      valueField: 'value',
      render: {
        no_results:function(data,escape){
          return '<div class="no-results">Désolé le lieu recherché n’existe pas ou ce service n’y est pas disponible, veuillez nous contacter si vous pensez que ce n’est pas normal</div>';
        }
      },
      load: (q, callback) => this.search(q, callback)
    }

    new TomSelect(this.element, config)
  }

  async search(q, callback) {

    var url = this.urlValue + '?q=' + encodeURIComponent(q);
    fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(()=>{
        callback();
      });

  }

}
