import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['contactAcceptance', 'phonePartnersContactAcceptance', 'emailPartnersContactAcceptance']

  toggle_contact_acceptance() {
    if(this.contactAcceptanceTarget.checked) {
      this.phonePartnersContactAcceptanceTarget.checked = true
      this.emailPartnersContactAcceptanceTarget.checked = true
    } else {
      this.phonePartnersContactAcceptanceTarget.checked = false
      this.emailPartnersContactAcceptanceTarget.checked = false
    }
  }

  check_general_acceptance() {
    if(this.phonePartnersContactAcceptanceTarget.checked){
      this.contactAcceptanceTarget.checked = true
    }

    if(this.emailPartnersContactAcceptanceTarget.checked){
      this.contactAcceptanceTarget.checked = true
    }
  }
}
