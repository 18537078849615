import L from "leaflet"
import "leaflet/dist/leaflet.css";

// This is needed because turbo was reloading the page because the leaflet popup close button
// is an anchor
document.addEventListener('turbo:click', function(event) {
  if (event.target.classList.contains('leaflet-popup-close-button')) {
    return event.preventDefault()
  }
})

document.addEventListener('turbo:load', function () {
  var maps = document.querySelectorAll('.js-leaflet-map')
  var mini_maps = document.querySelectorAll('.js-leaflet-mini-map')

  if (maps.length > 0) {
    maps.forEach(element => {
      var id = element.id;

      var leafletMap = L.map(id).setView([48, -1], 7);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 18,
        minZoom: 6,
      }).addTo(leafletMap);

      leafletMap.scrollWheelZoom.disable();
      leafletMap.on('focus', function() { leafletMap.scrollWheelZoom.enable(); });

      createMarkers(
        element,
        leafletMap
      );
    });

    var toggleInput = document.getElementById('toggle_map')
    if (toggleInput != null) {
      refreshUi(toggleInput);

      toggleInput.addEventListener('change', event => {
        refreshUi(event.currentTarget);
      });
    }
  }

  if (mini_maps.length > 0) {
    mini_maps.forEach(element => {
      var id = element.id;

      var leafletMap = L.map(id).setView([48, -1], 7);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 18,
        minZoom: 6,
      }).addTo(leafletMap);

      leafletMap.scrollWheelZoom.disable();
      leafletMap.on('focus', function() { leafletMap.scrollWheelZoom.enable(); });

      var markers = createMarkers(
        element,
        leafletMap
      );

      if(markers.length > 0){
        leafletMap.setView(markers[0]._latlng, 7)
      }
    });
  }
});

function createMarkers(node, leafletMap) {
  var LeafIcon = L.Icon.extend({
    options: {
      iconSize:     [30, 30],
      shadowSize:   [0, 0],
    }
  });

  var iconMarker = new LeafIcon({
    iconUrl: node.dataset.pinUrl,
    shadowUrl: ''
  });

  var customOptions = {
    'maxWidth': '400',
    'width': '200',
    'className' : 'leaflet-popup-content'
  };

  var marker_list = []

  node.querySelectorAll('.leaflet-custom-popup').forEach(function(element) {
    let marker = L.marker(
      [element.dataset.latitude, element.dataset.longitude],
      {icon: iconMarker}
    ).addTo(leafletMap);
    marker_list.push(marker)

    let customPopup = element.cloneNode(true)

    customPopup.classList.remove('hidden')

    marker.bindPopup(customPopup, customOptions);
  });

  return marker_list
}

function refreshUi(input) {
  if (input.checked) {
    document.getElementById(input.dataset.onMapId)
            .classList
            .remove('hidden');
    document.getElementById(input.dataset.offMapId)
            .classList
            .add('hidden');
  } else {
    document.getElementById(input.dataset.onMapId)
            .classList
            .add('hidden');
    document.getElementById(input.dataset.offMapId)
            .classList
            .remove('hidden');
  }
}
